import { Field, Form, Formik } from "formik";
import { atom, useAtom } from "jotai";
import { useNavigate, useParams } from "react-router-dom";
import { medicalVisitAtom } from "./AddCertificate";
import { formDataAtom } from "../App";
import Box from "../components/Box/Box";
import * as Yup from "yup";
import Progressbar from "../components/Progressbar/Progressbar";
import BackButton from "../components/Buttons/BackButton";
import ErrorList from "../components/Form/ErrorList";
import { useEffect, useRef, useState } from "react";
import { handleSubmitClick } from "../components/Form/FormUtils";
import useFocusOnLoad from "../hooks/useFocusOnLoad";

const ADD_MORE = "addMore";
const NO_MORE = "noMore";

type AddMoreFormValues = {
  addMore: string;
};

export const useIsAddMoreTrue = () => useAtom(isAddMoreTrueAtom);
export const isAddMoreTrueAtom = atom(false);
export default function AddMore() {
  const h2Ref = useFocusOnLoad<HTMLHeadingElement>();

  const [, setIsAddMoreTrue] = useIsAddMoreTrue();

  const { id } = useParams();
  const index = id === undefined ? 0 : Number.parseInt(id) - 1;
  const [mainFormData, setMainFormData] = useAtom(formDataAtom);
  const [formData, setFormData] = useAtom(medicalVisitAtom);
  const navigate = useNavigate();
  const isNewTravel = !mainFormData.medicalVisits[index];
  const formRef = useRef<HTMLFormElement>(null);
  const [shouldResetForm, setShouldResetForm] = useState(false);

  useEffect(() => {
    if (!isNewTravel && !shouldResetForm) {
      setFormData(mainFormData.medicalVisits[index]);
    }
  }, [id, isNewTravel, mainFormData.medicalVisits, setFormData, index, shouldResetForm]);
  
  useEffect(() => {
    if (shouldResetForm) {
      setFormData({
        certificate: [],
        wayOfTravel: "",
        receipts: [],
        travelDescription: "",
        comments: "",
        dateTo: "",
        dateFrom: "",
        otherFiles: [],
      });
      setShouldResetForm(false);
    }
  }, [shouldResetForm, setFormData]);
  return (
    <div>
      <Formik<AddMoreFormValues>
        onSubmit={(values) => {
          if (isNewTravel) {
            setMainFormData({
              ...mainFormData,
              medicalVisits: [...mainFormData.medicalVisits, formData],
            });
          } else {
            mainFormData.medicalVisits[index] = formData;
            setMainFormData({
              ...mainFormData,
              medicalVisits: [...mainFormData.medicalVisits],
            });
          }

          if (values.addMore === ADD_MORE) {
            setIsAddMoreTrue(true);
            setShouldResetForm(true);

            let offset = isNewTravel ? 2 : 1;
            navigate(
              "/reseuppgifter/" +
              (mainFormData.medicalVisits.length + offset) +
              "/intyg"
            );
          } else if (values.addMore === NO_MORE) {
            setIsAddMoreTrue(false);
            navigate("/granska-och-skicka");
          }
        }}
        initialValues={{
          addMore: isNewTravel ? "" : NO_MORE,
        }}
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object().shape({
          addMore: Yup.string().test(
            "at-least-one-radio-selected",
            "Vänligen välj ett svaralternativ för att gå vidare till nästa fråga",
            (value) => {
              return value === ADD_MORE || value === NO_MORE;
            }
          ),
        })}
      >
        {({ errors, values }) => (
          <>
            <div className="flex justify-between items-center">
              <div className="flex justify-center h-14 md:h-auto mb-1">
                <div className="w-full text-left md:hidden">
                  <BackButton />
                </div>
              </div>

              <div className="w-40 xs:w-52 md:w-full max-w-xs md:mb-4">
                <Progressbar percentage={"84"} />
              </div>
            </div>
            <Form ref={formRef} noValidate>
              <Box>
                <h2 ref={h2Ref} tabIndex={-1} id="AddMoreHeading">
                  Vill du lägga till fler resor för denna patient?
                </h2>

                <ErrorList errors={errors} />

                <div role="group" aria-labelledby="AddMoreHeading">
                  <fieldset className="radio-group">
                    {errors.addMore && (
                      <span
                        aria-describedby="forMe forOther"
                        className="validation-error"
                      >
                        {errors.addMore.toString()}
                      </span>
                    )}
                    <div className={errors.addMore ? "has-error" : ""}>
                      <Field
                        id="addMoreT"
                        type="radio"
                        name="addMore"
                        value={ADD_MORE}
                        required
                      />
                      <label htmlFor="addMoreT">
                        Ja{" "}
                        <span className="show-for-sr">
                          Lägg till fler resor
                        </span>
                      </label>
                    </div>
                    <div className={errors.addMore ? "has-error" : ""}>
                      <Field
                        id="noMoreT"
                        type="radio"
                        name="addMore"
                        value={NO_MORE}
                      />
                      <label htmlFor="noMoreT">
                        Nej, jag är klar och redo att skicka ansökan
                      </label>
                    </div>
                  </fieldset>
                </div>
              </Box>

              <div className="flex justify-center">
                <div className="w-full text-left flex items-center">
                  <BackButton />
                </div>
                <div className="w-full text-right">
                  <button
                    onClick={handleSubmitClick(formRef)}
                    className="btn secondary"
                    type="submit"
                  >
                    Gå vidare
                  </button>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
}